<script>
export default {
  name: "Policy",
};
</script>

<template>
  <div class="main-doc">
    <div class="_container">
      <div class="main-doc__title">Политика Конфиденциальности</div>
      <div class="main-doc__body">
        <div class="main-doc__paragraph">
          <div class="main-doc__content">
            <p>
              Сайт
              <a href="https://ligastudentov.com" target="_blank">
                ligastudentov.com
              </a>
              принадлежит Лига студентов Республики Татарстан, которая является
              контроллером для ваших личных данных. Мы используем эту Политику
              конфиденциальности, которая определяет, как мы обрабатываем
              информацию, собранную
              <a href="https://ligastudentov.com" target="_blank">
                ligastudentov.com </a
              >, а также причины, по которым мы должны собирать определённые
              личные данные о вас. Поэтому вы должны прочитать данную Политику
              конфиденциальности перед использованием сайта
              <a href="https://ligastudentov.com" target="_blank">
                ligastudentov.com </a
              >. Мы заботимся о ваших личных данных и гарантируем их
              конфиденциальность и безопасность.
            </p>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">
            Личная информация, которую мы собираем
          </div>
          <div class="main-doc__content">
            <p>
              При посещении
              <a href="https://ligastudentov.com" target="_blank">
                ligastudentov.com </a
              >, мы автоматически собираем определённую информацию о вашем
              устройстве, включая информацию о вашем веб-браузере, IP-адресе,
              часовом поясе и т. д. и некоторые из установленных файлов cookies
              на вашем устройстве. Кроме того, когда вы просматриваете Сайт, мы
              собираем информацию об отдельных страницах или продуктах, которые
              вы просматриваете, о том, какие сайты или условия поиска направили
              вас на Сайт и как вы взаимодействуете с Сайтом. Мы называем эту
              автоматически собранную информацию «Информация об устройстве».
              Кроме того, мы можем собирать личные данные, которые вы нам
              предоставляете (включая имя, фамилию, адрес, платежную информацию
              и т. д.) во время регистрации, чтобы иметь возможность выполнить
              соглашение.
            </p>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">
            Почему мы обрабатываем ваши данные?
          </div>
          <div class="main-doc__content">
            <p>
              Нашим главным приоритетом является безопасность клиентов, вот
              почему мы должны обрабатывать тот минимальный объем
              пользовательских данных: ровно столько, сколько необходимо для
              обслуживания сайта. Информация, собираемая автоматически,
              используется только для выявления потенциальных нарушений и
              получения статистической информации об использовании Сайта. Эта
              статистическая информация не собирается таким образом, чтобы можно
              было идентифицировать конкретного пользователя системы.
            </p>
            <p>
              Вы можете посещать Сайт, не сообщая нам, кто вы, и не раскрывая
              никакой информации, с помощью которой кто-либо мог бы
              идентифицировать вас как конкретное лицо. Однако, если вы хотите
              использовать некоторые функции сайта, хотите получать нашу
              рассылку новостей или другие сведения, заполнив форму, — вы можете
              предоставить нам личные данные, такие как ваш адрес электронной
              почты, имя, фамилия, место жительства, организации, телефона. Вы
              можете не предоставлять нам свои личные данные, но в этом случае
              вы не сможете воспользоваться определёнными функциями Сайта.
              Например, вы не сможете получать нашу рассылку новостей или
              связываться с нами напрямую через Сайт. Пользователи, которые не
              уверены, какая информация является обязательной, могут связаться с
              нами через
              <a href="mailto:ligatatarstan@gmail.com">
                ligatatarstan@gmail.com.
              </a>
            </p>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Ссылки на другие сайты</div>
          <div class="main-doc__content">
            <p>
              Наш Сайт может содержать ссылки на другие сайты, которые нам не
              принадлежат и не контролируются нами. Имейте в виду, что мы не
              несем ответственности за политику конфиденциальности подобных
              сайтов или третьих лиц. Мы рекомендуем вам проверять, когда вы
              покидаете наш Сайт и читаете заявления о конфиденциальности
              каждого сайта, который может собирать личную информацию.
            </p>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Информационная безопасность</div>
          <div class="main-doc__content">
            <p>
              Мы защищаем информацию, которую вы предоставляете на компьютерных
              серверах, в контролируемой безопасной среде, защищенной от
              несанкционированного доступа, использования или раскрытия. Мы
              принимаем разумные административные, технические и физические меры
              безопасности для защиты от несанкционированного доступа,
              использования, изменения и раскрытия личных данных, находящихся
              под его контролем и хранением. Однако передача данных через
              Интернет или беспроводную сеть не может быть гарантирована.
            </p>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Юридическое раскрытие</div>
          <div class="main-doc__content">
            <p>
              Мы будем раскрывать любую информацию, которую мы собираем,
              используем или получаем, если это требуется или разрешено законом,
              например, в соответствии с повесткой в суд или аналогичным
              судебным процессом, и когда мы добросовестно полагаем, что
              раскрытие информации необходимо для защиты наших прав, вашей
              безопасности или безопасности других лиц, расследования
              мошенничества или ответа на запрос правительства.
            </p>
          </div>
        </div>
        <div class="main-doc__paragraph">
          <div class="main-doc__subtitle">Контактная информация</div>
          <div class="main-doc__content">
            <p>
              Если вы хотите связаться с нами, чтобы узнать больше об этой
              Политике, или хотите связаться с нами по любому вопросу,
              касающемуся индивидуальных прав и вашей Личной информации, вы
              можете отправить письмо на адрес
              <a href="mailto:igatatarstan@gmail.com">
                ligatatarstan@gmail.com.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
